import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import loadable from '@loadable/component';

import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import './globals.scss';

const Layout = loadable(() => import('./layout/Layout'));

// reseller pages imports
const ResellerPage = loadable(() => import('./sections/Reseller/ResellerPage'));
const ResellerThankYouPage = loadable(() => import('./sections/Reseller/ThankYouPage'));
const ResellerNoPage = loadable(() => import('./sections/Reseller/404Page'));

// google analytics and tag manager
const TRACKING_ID = "UA-2171185-13";
ReactGA.initialize(TRACKING_ID);
let gtmIDnum;

if(window.location.href.indexOf("joinbeam") > -1) {
    gtmIDnum = 'GTM-K7SGNK4'
} else { gtmIDnum = 'GTM-WJH797S' }

const tagManagerArgs = {
    gtmId: gtmIDnum
}
TagManager.initialize(tagManagerArgs);

// App
const App = () => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    
    const route = (
            <Route path="/" element={<Layout />}>
                <Route index element={<ResellerPage />} />
                <Route path="*" element={<ResellerNoPage />} />
                <Route path="/thank-you" element={<ResellerThankYouPage />} />
            </Route>
        )

    return (
        <div className={'reseller-theme'} style={{ display: 'block', position: 'relative', width: '100%', height: '100%', maxWidth: '100%' }}>
            <BrowserRouter>
                <Routes>
                    {route}
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;