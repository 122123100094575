import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'typeface-jost'
import 'typeface-lato'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>, 
  document.getElementById('root'));